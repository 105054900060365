<template>
    <div>
        <div id="info" >
            <v-img src="/weblogo2.png" :max-height="logohight" :max-width="logowidth"></v-img>
            <v-spacer></v-spacer>

            <div class="aaa" v-if="this.windowSize > 550">
                <v-btn icon x-large href="https://opensea.io/collection/lets-doodle-1">
                    <v-icon>mdi-sail-boat</v-icon>
                </v-btn>
                
                <v-btn icon x-large  href="https://twitter.com/LetsDoodle_nft">
                    <v-icon >mdi-twitter</v-icon>
                </v-btn>

                <v-btn icon x-large href="https://discord.com/invite/PVNYvgTtWR">
                    <v-icon>mdi-discord</v-icon>
                </v-btn>
            </div>
            <div class="aaa" v-if="this.windowSize <= 550">
                <v-btn icon small href="https://opensea.io/collection/lets-doodle-1">
                    <v-icon>mdi-sail-boat</v-icon>
                </v-btn>
                
                <v-btn icon small  href="https://twitter.com/LetsDoodle_nft">
                    <v-icon >mdi-twitter</v-icon>
                </v-btn>

                <v-btn icon small href="https://discord.com/invite/PVNYvgTtWR">
                    <v-icon>mdi-discord</v-icon>
                </v-btn>
            </div>

        </div>
        <canvas id="container"></canvas>
    </div>
</template>
<script>
import * as THREE from 'three'; // three.jsをimport

// import { GUI } from 'three/examples/jsm/libs/dat.gui.module.js';

// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DecalGeometry } from 'three/examples/jsm/geometries/DecalGeometry.js';

export default {
    name: 'test',

    data: () => {
        return {
            windowSize: 0,
            logowidth : '150px',
            logohight : '70px',
            container: document.getElementById('container'),

            renderer: '',
            scene: '',
            camera: '',
            stats: '',
            sphereInter: '',
            mesh: '',
            cube: '',
            plate: '',
            raycaster: '',
            controls: '',

            intersection: {
                intersects: false,
                point: '',
                normal: ''
            },
            mouse: '',
            intersects: [],

            textureLoader: '',
            decalDiffuse: '',
            decalNormal: '',

            decalMaterial: '',

            decals: [],
            // デカールのカラー(htmlランダム生成時に変更)
            // VALIABLE
            decalColor: [
                0xFF66FF,
                0x66FF99,
                0xFF9933,
                0xCCFF33,
                0x33FFFF,
                0x0033FF,
                0xFF66CC,
                0xE6E6FA,
                0xFAFAD2,
                0xFFCCCC
            ],
            mouseHelper: '',
            position: '',
            orientation: '',
            size: '',

            params: {
                minScale: 20,
                maxScale: 40,
                rotate: '',
                clear: ''
            },
            EVENT: {},
            fov: 60,
            onMouseDownMouseX: 0,
            onMouseDownMouseY: 0,
            lon: -0.55,
            onMouseDownLon: 0,
            lat: 0.92, 
            onMouseDownLat: 0
        }
    },
    created(){
        this.windowSize = window.innerWidth
        this.logowidth = this.windowSize > 550 ? '250px' : '150px'
        this.logohight = this.windowSize > 550 ? '70px' : '42px'
        this.intersection.point = new THREE.Vector3()
        this.intersection.normal = new THREE.Vector3()
        this.mouse = new THREE.Vector2()
        this.textureLoader = new THREE.TextureLoader()
        this.position = new THREE.Vector3()
        this.orientation = new THREE.Euler()
        this.size = new THREE.Vector3(10, 10, 10)
        if ('ontouchstart' in window) {
          this.EVENT.TOUCH_START = 'touchstart';
          this.EVENT.TOUCH_MOVE = 'touchmove';
          this.EVENT.TOUCH_END = 'touchend';
        } else {
          this.EVENT.TOUCH_START = 'mousedown';
          this.EVENT.TOUCH_MOVE = 'mousemove';
          this.EVENT.TOUCH_END = 'mouseup';
        }
        this.cube = new  THREE.Object3D();
    },
    mounted () {

        this.init()
    },
    methods: {
        init() {
            const vue = this

            this.renderer = new THREE.WebGLRenderer({ antialias: true, canvas: document.querySelector('#container') });
            // レンダラー設定
            this.renderer.setPixelRatio( window.devicePixelRatio );
            this.renderer.autoClear = false;
            this.renderer.setPixelRatio(window.devicePixelRatio);
            this.renderer.setSize(window.innerWidth, window.innerHeight);

            // stats = new Stats();
            // container.appendChild(stats.dom);

            // シーンオブジェクトを生成
            this.scene = new THREE.Scene();
            // 背景色を選択(html自動生成時に変更)
            this.scene.background = new THREE.Color(0xfff000);

            this.camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 1000);
            this.camera.position.x = 0;
            this.camera.position.y = 0;
            this.camera.position.z = 120;

            // this.controls = new OrbitControls(this.camera, this.renderer.domElement);
            // this.controls.minDistance = 50;
            // this.controls.maxDistance = 200;
            // this.controls.autoRotateSpeed = 5.0;
            
            


            this.decalDiffuse = this.textureLoader.load('/decal/decal-diffuse.png')
            this.decalNormal = this.textureLoader.load('/decal/decal-normal.jpg')
            this.decalMaterial = new THREE.MeshPhongMaterial({
                    specular: 0x444444,
                    map: this.decalDiffuse,
                    normalMap: this.decalNormal,
                    normalScale: new THREE.Vector2(1, 1),
                    shininess: 30,
                    transparent: true,
                    depthTest: true,
                    depthWrite: false,
                    polygonOffset: true,
                    polygonOffsetFactor: - 4,
                    wireframe: false
                })

            this.scene.add(new THREE.AmbientLight(0x443333));

            const dirLight1 = new THREE.DirectionalLight(0xffddcc, 1);
            dirLight1.position.set(-1, 0.75, 0.5);
            this.scene.add(dirLight1);

            const dirLight2 = new THREE.DirectionalLight(0xccccff, 1);
            dirLight2.position.set(- 1, 0.75, - 0.5);
            this.scene.add(dirLight2);

            const geometry = new THREE.BufferGeometry();
            geometry.setFromPoints([new THREE.Vector3(), new THREE.Vector3()]);

            // 照準オブジェクトの生成
            const geometryShape = new THREE.SphereGeometry(1);
            const material = new THREE.MeshBasicMaterial({ color: 0xff0000 });
            this.sphereInter = new THREE.Mesh(geometryShape, material);
            this.sphereInter.visible = false;
            this.scene.add(this.sphereInter);

            let geometry3 = new THREE.BoxGeometry(25, 25, 25);
    
            // ======================================
            // let texture2 = this.textureLoader.load('/logo.png')
            // let texture3 = this.textureLoader.load('/desc.png')
            // let material2 = [
            // new THREE.MeshBasicMaterial({map: this.textureLoader.load( '/logo.png' )}),
            // new THREE.MeshBasicMaterial({map: this.textureLoader.load( '/desc.png' )}),
            // new THREE.MeshBasicMaterial({map: this.textureLoader.load( '/logo.png' )}),
            // new THREE.MeshBasicMaterial({map: this.textureLoader.load( '/desc.png' )}),
            // new THREE.MeshBasicMaterial({map: this.textureLoader.load( '/logo.png' )}),
            // new THREE.MeshBasicMaterial({map: this.textureLoader.load( '/desc.png' )}),
            // ];

            let material3 = new THREE.MeshBasicMaterial( { map: this.textureLoader.load( '/bg/airbg.jpeg' ) } );
            // let material3 = new THREE.MeshBasicMaterial( { color: "#99ccff" } );

            // ======================================
            
            this.plate = new THREE.Mesh( geometry3, material3 );
            // this.mesh = cube
            this.scene.add( this.plate );
            this.plate.scale.set(20, 9, 1);
            this.plate.position.set(20, 0, -130);


            let geometry2 = this.windowSize > 550 ? new THREE.BoxGeometry(25, 25, 25) : new THREE.BoxGeometry(15, 15, 15);
    
            // ======================================
            // let texture2 = this.textureLoader.load('/logo.png')
            // let texture3 = this.textureLoader.load('/desc.png')
            let material2 = [
            new THREE.MeshBasicMaterial({map: this.textureLoader.load( '/faq.png' )}),// right
            new THREE.MeshBasicMaterial({map: this.textureLoader.load( '/Gallery_2.png' )}),// left
            new THREE.MeshBasicMaterial({map: this.textureLoader.load( '/cubelogo.png' )}),// upper
            new THREE.MeshBasicMaterial({map: this.textureLoader.load( '/CommingSoon.png' )}),// back
            new THREE.MeshBasicMaterial({map: this.textureLoader.load( '/Description_3.png' )}),// front
            new THREE.MeshBasicMaterial({map: this.textureLoader.load( '/CommingSoon.png' )}),// back
            ];

            // let material2 = new THREE.MeshBasicMaterial( { map: texture3 } );

            // ======================================
            
            this.mesh = new THREE.Mesh( geometry2, material2 );
            this.mesh.scale.set(2, 2, 2);
            this.cube.add(this.mesh)
            // this.scene.add( this.mesh );
            this.scene.add( this.cube );

            // this.loadModel();
            // this.loadLeePerrySmith();

            this.raycaster = new THREE.Raycaster();

            this.mouseHelper = new THREE.Mesh(new THREE.BoxGeometry(1, 1, 10), new THREE.MeshNormalMaterial());
            this.mouseHelper.visible = false;
            this.scene.add(this.mouseHelper);

            window.addEventListener('resize', this.onWindowResize);

            let moved = false;

            // this.controls.addEventListener('change', function () {

            //     moved = true;

            // });

            window.addEventListener('pointerdown', function () {

                moved = false;

            });

            window.addEventListener('pointerup', function (event) {
                if (moved === false) {

                    checkIntersection(event.clientX, event.clientY);

                    if (vue.intersection.intersects) vue.shoot();

                }

            });

            window.addEventListener('pointermove', onPointerMove);
            document.addEventListener( this.EVENT.TOUCH_START, this.onDocumentMouseDown, false );
            document.addEventListener( 'mousewheel', this.onDocumentMouseWheel, false );
            document.addEventListener( 'MozMousePixelScroll', this.onDocumentMouseWheel, false);


            function onPointerMove(event) {

                if (event.isPrimary) {
                    checkIntersection(event.clientX, event.clientY);

                }

            }

            window.addEventListener('keydown', onKeydown);

            function onKeydown(event) {
                console.log("keyCode=" + event.keyCode);
                if (event.keyCode == 82) { // 「r」
                    vue.rotate(); // 回転
                } else if (event.keyCode == 67) { // 「c」
                    vue.removeDecals(); // クリア
                } else if (event.keyCode == 83) { // [s]
                    vue.renderer.render(vue.scene, vue.camera);
                    // this.container.toBlob((blob) => {
                    //     screenshot(blob, 'capture.png');
                    // });
                }
            }

            function checkIntersection(x, y) {

                if (vue.cube === undefined) return;

                vue.mouse.x = (x / window.innerWidth) * 2 - 1;
                vue.mouse.y = - (y / window.innerHeight) * 2 + 1;

                vue.raycaster.setFromCamera(vue.mouse, vue.camera);
                vue.raycaster.intersectObjects([vue.cube.children[0], vue.plate], false, vue.intersects);
                // vue.raycaster.intersectObject(vue.mesh, false, vue.intersects);

                if (vue.intersects.length > 0) {

                    const p = vue.intersects[0].point;
                    vue.mouseHelper.position.copy(p);
                    vue.intersection.point.copy(p);

                    const n = vue.intersects[0].face.normal.clone();
                    n.transformDirection(vue.cube.matrixWorld);
                    n.multiplyScalar(10);
                    n.add(vue.intersects[0].point);

                    vue.intersection.normal.copy(vue.intersects[0].face.normal);
                    vue.mouseHelper.lookAt(n);

                    vue.sphereInter.visible = true;
                    vue.sphereInter.position.copy(vue.intersects[0].point);
                    vue.intersection.intersects = true;

                    vue.intersects.length = 0;

                } else {

                    vue.intersection.intersects = false;
                    // this.sphereInter.visible = false;
                }

                // マウスカーソルの表示/非表示を判定
                if (vue.intersection.intersects) {
                    document.body.classList.add('hideCursor');
                } else {
                    document.body.classList.remove('hideCursor');
                }

            }

            // const gui = new GUI();

            // gui.add(this.params, 'minScale', 1, 30);
            // gui.add(this.params, 'maxScale', 1, 30);
            // gui.add(this.params, 'rotate');
            // gui.add(this.params, 'screenshot');
            // gui.add(this.params, 'clear');
            // gui.open();

            this.onWindowResize();
            this.animate();

        },
        loadModel() {
            const vue = this
            const loader = new GLTFLoader();

            // モデルのロード(html自動生成時にパスを変更)
            loader.load('/models/test/test.glb', function (gltf) {

                let meshes = gltf.scene.children.filter(function (element) {
                    return element.type === 'Mesh';
                });
                if (meshes.length) {
                    vue.mesh = meshes[0];
                    // mesh.material = new THREE.MeshPhongMaterial({
                    //     specular: 0x111111,
                    //     map: textureLoader.load('models/test/test.png'),
                    //     // specularMap: textureLoader.load('models/LeePerrySmith/Map-SPEC.jpg'),
                    //     // normalMap: textureLoader.load('models/LeePerrySmith/Infinite-Level_02_Tangent_SmoothUV.jpg'),
                    //     shininess: 25
                    // });

                    // this.scene.add(mesh);
                    // mesh.scale.set(10, 10, 10);
                    vue.scene.add(gltf.scene);
                    gltf.scene.scale.set(10, 10, 10);
                } else {
                    console.log("Failed to load the model.")
                }
            });
        },
        shoot() {

            this.position.copy(this.intersection.point);
            this.orientation.copy(this.mouseHelper.rotation);

            // if (this.params.rotate) orientation.z = Math.random() * 2 * Math.PI;

            const scale = this.params.minScale + Math.random() * (this.params.maxScale - this.params.minScale);
            this.size.set(scale, scale, scale);

            const material = this.decalMaterial.clone();
            // material.color.setHex(Math.random() * 0xffffff);
            material.color.setHex(this.decalColor[Math.floor(Math.random() * this.decalColor.length)] ); // 配列からランダム抽出

            const m = new THREE.Mesh(new DecalGeometry(this.cube.children[0], this.position, this.orientation, this.size), material);
            const m2 = new THREE.Mesh(new DecalGeometry(this.plate, this.position, this.orientation, this.size), material);

            this.decals.push(m);
            this.cube.attach(m)
            // this.scene.add(m);
            this.decals.push(m2);
            this.scene.add(m2);

        },
        // rotate() {
        //     this.controls.autoRotate = !this.controls.autoRotate;
        // },
        removeDecals() {
            const vue = this

            this.decals.forEach(function (d) {
                vue.cube.remove(d)

                vue.scene.remove(d);

            });

            this.decals.length = 0;

        },
        onWindowResize() {

            this.camera.aspect = window.innerWidth / window.innerHeight;
            this.camera.updateProjectionMatrix();

            this.renderer.setSize(window.innerWidth, window.innerHeight);

        },

        // メソッドを実装します。
        animate() {

            requestAnimationFrame(this.animate);

            this.cube.rotation.y = this.lon;
            this.cube.rotation.x = this.lat;

            this.renderer.render(this.scene, this.camera);

            // stats.update();

            // this.controls.update();


        },
        onDocumentMouseDown( event ) {
          event.preventDefault();
          if(event.clientX) {
            this.onMouseDownMouseX = event.clientX;
            this.onMouseDownMouseY = event.clientY;
          } else if(event.touches) {
            this.onMouseDownMouseX = event.touches[0].clientX
            this.onMouseDownMouseY = event.touches[0].clientY;
          } else {
            this.onMouseDownMouseX = event.changedTouches[0].clientX
            this.onMouseDownMouseY = event.changedTouches[0].clientY
          }
          this.onMouseDownLon = this.lon;
          this.onMouseDownLat = this.lat;
          document.addEventListener( this.EVENT.TOUCH_MOVE, this.onDocumentMouseMove, false );
          document.addEventListener( this.EVENT.TOUCH_END, this.onDocumentMouseUp, false );
        },
        onDocumentMouseMove( event ) {
          event.preventDefault();
          let touchClientX = 0
          let touchClientY = 0

          if(event.clientX) {
            touchClientX = event.clientX;
            touchClientY = event.clientY;
          } else if(event.touches) {
            touchClientX = event.touches[0].clientX
            touchClientY = event.touches[0].clientY;
          } else {
            touchClientX = event.changedTouches[0].clientX
            touchClientY = event.changedTouches[0].clientY
          }
          this.lon = ( touchClientX - this.onMouseDownMouseX ) * 0.01 + this.onMouseDownLon;
          this.lat = ( touchClientY - this.onMouseDownMouseY ) * 0.01 + this.onMouseDownLat;

        },
        onDocumentMouseUp(  ) {
          document.removeEventListener( this.EVENT.TOUCH_MOVE, this.onDocumentMouseMove, false );
          document.removeEventListener( this.EVENT.TOUCH_END, this.onDocumentMouseUp, false );
        },
        onDocumentMouseWheel( event ) {
            // WebKit
            if ( event.wheelDeltaY ) {
            this.fov -= event.wheelDeltaY * 0.05;
            // Opera / Explorer 9
            } else if ( event.wheelDelta ) {
            this.fov -= event.wheelDelta * 0.05;
            // Firefox
            } else if ( event.detail ) {
            this.fov += event.detail * 1.0;
            }
            // this.camera.projectionMatrix.makePerspective( this.fov, this.container.clientWidth / this.container.clientHeight, 1, 110 );
        }
    }
}
</script>
<style>
#info {
	position: absolute;
	top: 0px;
	width: 100%;
	padding: 10px;
	box-sizing: border-box;
	text-align: center;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	pointer-events: none;
	z-index: 1; /* TODO Solve this in HTML */
}
a, button, input, select {
	pointer-events: auto;
}
.aaa {
    position: fixed; /* ウィンドウを基準に画面に固定 */
    top: 12px; /* 上下の固定位置を上から0pxにする */
    right: 20px; /* 左右の固定位置を左から0pxにする */
    display: flex; /* 中の要素を横並びにする */
}

</style>
