<template>
  <div id="app">
    <Top />
  </div>
</template>

<script>
import Top from './components/Top.vue'
// import Description from './components/Description.vue'
// import Faq from './components/Faq.vue'
// import Gallery from './components/Gallery.vue'
// import Header from './components/Header.vue'

export default {
  name: 'App',
  components: {
    Top,
    // Description,
    // Faq,
    // Gallery,
    // Header
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.hello {
  height: 300px;
}
html, body{
  overflow: hidden;
}
</style>
